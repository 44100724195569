<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row>
        <br><br><br />

        <v-card class="card">
            <v-card-title class="heading justify-center">Transfer Issue Book</v-card-title>
            <v-card-text>
                <div class="add-section">

                    <br>

                    <v-form ref="form">
                        <v-row class="justify-content-center">

                            <v-col lg="5" sm="12" md="5" class="import-box">

                                <label class="label"> Action/UserType </label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-autocomplete class="text" :items="action_list" v-model="selected_action" item-text="name"
                                    item-value="id" autocomplete="off" @change="getusername()" outlined dense clearable
                                    required></v-autocomplete>
                                <label class="add-text">Username</label><span style="color: red;"> *</span>
                                <v-autocomplete class="text" v-if="!loadingUsernames" v-model="Usernameformaction"
                                    :items="Usernamelist" item-text="displayname" item-value="userid" autocomplete="off"
                                    outlined dense clearable required @change="set1(Usernameformaction)"></v-autocomplete>
                                <div v-else>
                                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                                </div>
                                <v-btn class="primary" @click="getissuebook(prn, selected_action)">Fetch
                                    Issue Book</v-btn>


                            </v-col>
                            <v-col lg="5" sm="12" md="5" class="import-box">
                                <br>
                                <br>
                                <br>

                                <label class="label">IMPORT CSV</label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-file-input dense v-model="file" persistent-hint clearable color="rgb(1, 127, 152)"
                                    label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                                </v-file-input>



                                <v-btn :loading="output_load" @click="importCsv" class="primary">
                                    Import
                                    <v-icon right>mdi-cloud-upload</v-icon>
                                </v-btn>

                            </v-col>
                        </v-row>
                        <v-card v-if="employeehistory.length != 0" class="pa-4">
                            <span class="pa-3">
                                Name:<b class="pr-10"> {{ employeehistoryname }}</b> Username:
                                <b>{{ employeehistoryuser }}</b>
                            </span>
                            <v-col class="text-right">
                                <span>
                                    Select All
                                    <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px"
                                        type="checkbox" @click="checkAll(employeehistory)" />
                                </span>
                            </v-col>

                            <v-data-table v-if="employeehistory.length != 0" :headers="employehistoryheader"
                                :items="employeehistory" class="elevation-1 nowrap" :search="search">
                                <template v-slot:item.iscurrent="{ item }">
                                    <v-icon class="green--text" v-if="item.iscurrent">mdi
                                        mdi-check-circle-outline</v-icon>
                                </template>

                                <template v-slot:item.istransfer="{ item }">
                                    <input type="checkbox" style="height: 30px; width: 30px" :ripple="false"
                                        v-model="item.istransfer" />
                                </template>

                            </v-data-table>
                            <v-row class="justify-content-center">

                                <v-col lg="5" sm="12" md="5" class="import-box">
                                    <label class="label"> Action/UserType </label><span
                                        style="color: red; font-weight: bolder">*</span>
                                    <v-autocomplete class="text" :items="action_list" v-model="transfer_selected_action"
                                        item-text="name" item-value="id" autocomplete="off"
                                        @change="transfergetusername()" outlined dense clearable required></v-autocomplete>
                                    <label class="add-text">Username</label><span style="color: red;"> *</span>
                                    <v-autocomplete class="text" v-if="!loadingtransferUsernameformaction"
                                        v-model="transferUsernameformaction" :items="transferUsernamelist"
                                        item-text="displayname" item-value="userid" autocomplete="off" outlined dense
                                        clearable required @change="set2(transferUsernameformaction)"></v-autocomplete>
                                    <div v-else>
                                        <v-skeleton-loader v-bind="attrs"
                                            type="three-line,  article"></v-skeleton-loader>
                                    </div>

                                    <v-btn class="primary" @click="filterTransferbook">Transfer
                                        Issue Book</v-btn>


                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>
                    <v-dialog v-model="confirmdiaog" width="1000">
                        <v-card>
                            <v-card-title class="grey lighten-2">
                                Are you Sure You Want To Transfer Issue Book?
                            </v-card-title>

                            <v-card-text style="padding-bottom: 0px;">
                                <div v-for="(item, index) in selected_book_name_for_transfer" :key="index">
                                    <b>{{ item }}</b>
                                    <hr style="padding:0px; margin:0px;" />

                                </div>
                                <center><span>Total Selected Book:{{ total_count }}</span></center>
                            </v-card-text>



                            <div class="text-center">
                                <v-spacer></v-spacer>
                                <v-btn class="m-3" @click="confirmdiaog = false">
                                    No,Cancel
                                </v-btn>
                                <v-btn class="m-3 red white--text" @click="TransferIssueBook()">YES,Transfer</v-btn>
                            </div>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="reportdialog" persistent width="1500">
                        <v-card class="pa-3">


                            <v-card-text style="padding-bottom: 0px;">

                                <template>
                                    <h5 v-if="successlist.length > 0" class="mt-4"
                                        style="text-align: center; color: green;">Success Record
                                    </h5>
                                    <v-btn v-if="successlist.length > 0" @click="exportexcel1()" color="success"
                                        style="float:right;" dark>
                                        <v-icon dark>mdi-file-excel</v-icon>
                                    </v-btn>
                                    <v-simple-table v-if="successlist.length > 0" id="exceltable1">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Titile</th>
                                                    <th>Assection No</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in successlist" :key="item.name" style="color:green;">
                                                    <th>{{ item.row }} </th>
                                                    <th>{{ item.title }} </th>
                                                    <th>{{ item.Assection_No }} </th>
                                                    <th>{{ item.msg }} </th>

                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>

                                <template>
                                    <h5 v-if="failList.length > 0" class="mt-4"
                                        style="text-align: center; color: green;">
                                        Success Record
                                    </h5>
                                    <v-btn v-if="failList.length > 0" @click="exportexcel()" color="success"
                                        style="float:right;" dark>
                                        <v-icon dark>mdi-file-excel</v-icon>
                                    </v-btn>
                                    <v-simple-table v-if="failList.length > 0" id="exceltable">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Titile</th>
                                                    <th>Assection No</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in failList" :key="item.name" style="color:green;">
                                                    <th>{{ item.row }} </th>
                                                    <th>{{ item.title }} </th>
                                                    <th>{{ item.Assection_No }} </th>
                                                    <th>{{ item.msg }} </th>

                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-card-text>


                            <div class="text-center">
                                <v-spacer></v-spacer>
                                <v-btn class="m-3" @click="close11()">
                                    Close
                                </v-btn>

                            </div>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="reportdialog1" persistent width="1500">
                        <v-card class="pa-3">


                            <v-card-text style="padding-bottom: 0px;">

                                <template>
                                    <h5 v-if="success_list.length > 0" class="mt-4"
                                        style="text-align: center; color: green;">Success Record
                                    </h5>
                                    <v-btn v-if="success_list.length > 0" @click="exportexcel1()" color="success"
                                        style="float:right;" dark>
                                        <v-icon dark>mdi-file-excel</v-icon>
                                    </v-btn>
                                    <v-simple-table v-if="success_list.length > 0" id="exceltable1">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Titile</th>
                                                    <th>Assection No</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in success_list" :key="item.name" style="color:green;">
                                                    <th>{{ item.row }} </th>
                                                    <th>{{ item.title }} </th>
                                                    <th>{{ item.Assection_No }} </th>
                                                    <th>{{ item.msg }} </th>

                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>

                                <template>
                                    <h5 v-if="failList.length > 0" class="mt-4"
                                        style="text-align: center; color: green;">
                                        Success Record
                                    </h5>
                                    <v-btn v-if="failList.length > 0" @click="exportexcel()" color="success"
                                        style="float:right;" dark>
                                        <v-icon dark>mdi-file-excel</v-icon>
                                    </v-btn>
                                    <v-simple-table v-if="failList.length > 0" id="exceltable">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Titile</th>
                                                    <th>Assection No</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in failList" :key="item.name" style="color:green;">
                                                    <th>{{ item.row }} </th>
                                                    <th>{{ item.title }} </th>
                                                    <th>{{ item.Assection_No }} </th>
                                                    <th>{{ item.msg }} </th>

                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-card-text>


                            <div class="text-center">
                                <v-spacer></v-spacer>
                                <v-btn class="m-3" @click="close12()">
                                    Close
                                </v-btn>

                            </div>
                        </v-card>
                    </v-dialog>
                </div>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { table2excel } from "../jquery.table2excel";
import axios from "axios";
import "@/assets/css/color.css";
export default {
    data: () => ({
        valid: false,
        overlay: false,
        file: null,
        failList: [],
        success_list: [],
        Usernamelist: [],
        transferUsernamelist: [],
        output_load: false,
        loadingUsernames: false,
        loadingtransferUsernameformaction: false,
        successlist: [],
        failList: [],
        employeehistory: [],
        employeehistoryuser: "",
        employeehistoryname: "",
        selected_book_id_for_transfer: [],
        selected_book_name_for_transfer: [],
        total_count: 0,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Transfer Issue Book',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        confirmdiaog: false,
        reportdialog: false,
        reportdialog1: false,
        selectall: true,
        isLoading: false,
        action_list: [

            {
                id: "1",
                name: 'Employee'
            }
            , { id: "2", name: 'Learner' }],
        selected_action: "",
        transfer_selected_action: "",

        snackbar_msg: "",
        color: "",

        prn: "",
        transferprn: "",
        Usernameformaction: "",
        transferUsernameformaction: "",
        snackbar: false,
        search: "",
        employehistoryheader: [
            {
                text: "Sr no",
                sortable: true,
                value: "srno",
            },
            {
                text: "Accession No",
                sortable: true,
                value: "Assection_No",
            },
            {
                text: "Title",
                sortable: true,
                value: "title",
            },

            {
                text: "Issue Date",
                sortable: true,
                value: "borrowing_date",
            },
            {
                text: "Due Date",
                sortable: true,
                value: "due_date",
            },
            {
                text: "Return Date",
                sortable: true,
                value: "return_date",
            },
            {
                text: "is Current",
                sortable: true,
                value: "iscurrent",
            },
            {
                text: "is Transfer",
                sortable: true,
                value: "istransfer",
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
    }),

    methods: {
        exportexcel() {

            $("#exceltable").table2excel({
                name: "Failed Record Book",
                exclude_img: false,
                filename: "Failed_Transfer_issue_book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        exportexcel1() {

            $("#exceltable1").table2excel({
                name: "Success Record Book",
                exclude_img: false,
                filename: "Success_Transfer_issue_book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        importCsv() {

            let formData = new FormData();
            if (this.file == null) {
                this.showSnackbar("red", "Please Select File");
                return
            }
            if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                this.showSnackbar("red", "File must be in CSV format Only.!");
                return
            }

            this.fileStatus = true;
            formData.append("file", this.file);
            this.isLoading = true;
            axios
                .post("IssueBook/importTransferIssuebook", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {

                    this.failList = res.data.failList;
                    this.success_list = res.data.success_list;
                    this.isLoading = false;
                    this.file = null;
                    // this.showSnackbar("red", res.data.msg);
                    if (res.data.status == "200") {
                        if (this.failList.length != 0 || this.success_list.length != 0)
                            this.reportdialog1 = true;

                    }
                })




        },
        close11() {
            this.reportdialog = false;
            window.location.reload();
        },
        close12() {
            this.reportdialog1 = false;
            window.location.reload();
        },
        exportexcel() {

            $("#exceltable").table2excel({
                name: "Failed  Record ",
                exclude_img: false,
                filename: "Failed_issue_book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        exportexcel1() {

            $("#exceltable1").table2excel({
                name: "Success  Record",
                exclude_img: false,
                filename: "Success_issue_book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        TransferIssueBook() {

            var params = {
                bookLendingid: this.selected_book_id_for_transfer,
                user_id: this.transferprn,
                form_user_id: this.prn,
            };
            this.loader = false;
            axios
                .post("/IssueBook/transferselectedIssuebook", params)
                .then((res) => {
                    if (res.data.status == "200") {
                        this.loader = false;
                        this.failList = res.data.failList;
                        this.successlist = res.data.successlist;
                        if (this.failList.length != 0 || this.successlist.length != 0)
                            this.reportdialog = true;
                        this.confirmdiaog = false;


                    } else {

                        this.loader = false;
                        this.confirmdiaog = false;


                    }
                });
        },
        filterTransferbook() {
            if (this.transfer_selected_action == null || this.transfer_selected_action == '') {
                this.showSnackbar("red", "please Select Action/User Type");
                return;
            }
            if (this.transferUsernameformaction == null || this.transferUsernameformaction == '') {
                this.showSnackbar("red", "please Select Username");
                return;
            }
            this.total_count = 0;
            this.selected_book_id_for_transfer = [];
            this.selected_book_name_for_transfer = [];
            for (let x of this.employeehistory) {
                if (x.istransfer) {
                    this.selected_book_id_for_transfer.push(x.id);
                    this.selected_book_name_for_transfer.push(x.Assection_No + ":" + x.title)
                    this.total_count = this.total_count + 1;
                }
            }
            if (this.selected_book_id_for_transfer == [] || this.selected_book_id_for_transfer == null || this.selected_book_id_for_transfer.length == 0) {
                this.showSnackbar("red", "please Select Book item");
            }
            else {
                this.confirmdiaog = true;
            }
        },
        checkAll(hello) {

            for (var i = 0; i < this.employeehistory.length; i++) {
                if (!this.selectall) {
                    this.employeehistory[i].istransfer = true;
                } else {
                    this.employeehistory[i].istransfer = false;
                }
            }
        },
        getissuebook(prn, selected_action) {
            var params = null;
            this.overlay = true;
            params = {
                userid: prn,
                selected_action: selected_action
            };
            axios.post("/IssueBook/fetchIssueBook", params).then((res) => {
                this.overlay = false;
                if (res.data.msg == "200") {
                    this.employeehistory = res.data.data;
                    this.employeehistoryname = res.data.name;
                    this.employeehistoryuser = res.data.user;
                    this.isemphistory = true;
                } else {
                    this.showSnackbar("#b71c1c", res.data.msg);

                }
            });

        },
        set1(value) {
            this.prn = value;
        },
        set2(value) {
            this.transferprn = value;
        },
        getusername() {
            this.loadingUsernames = true;
            let formData = new FormData();
            formData.append("selected_action", this.selected_action);
            axios
                .post("/IssueBook/getusernameData", formData, {
                }).then((res) => {
                    if (res.data.msg == "200") {
                        this.loadingUsernames = false;
                        this.Usernamelist = res.data.userlist;
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        this.loadingUsernames = false;
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },
        transfergetusername() {
            this.loadingtransferUsernameformaction = true;
            let formData = new FormData();
            formData.append("selected_action", this.transfer_selected_action);
            axios
                .post("/IssueBook/gettransferusernameData", formData, {
                }).then((res) => {
                    if (res.data.msg == "200") {
                        this.loadingtransferUsernameformaction = false;
                        this.transferUsernamelist = res.data.userlist;
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        this.loadingtransferUsernameformaction = false;
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
        },
    },
};
</script>

<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.add-section {
    display: block;
}

.import-box {
    margin: 1rem 1rem 2rem 1rem;
    border: 2px solid;
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    background: #ffffffb5;
}

.label {
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.button {
    background: white !important;
}

.set-btn {
    margin: 0rem 1rem 0rem 1rem;
    padding-top: 1rem;
}

.button:hover {
    color: white;
    background: black !important;
}

.file-warn {
    font-style: italic;
    color: red;
    text-align: center;
    margin: 0;
}
</style>
